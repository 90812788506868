import React from 'react'
import OtherNavbar from './../navbar/OtherNavbar'
import './About.css'
import mission from './../../images/about/mission.jpg'
import vision from './../../images/about/vision.jpg'
import privacy from './../../images/about/privacy.jpg'
import confidence from './../../images/about/confidence.jpg'

const About = () => {
  return (
    <div>

        <OtherNavbar/>
        <div className="container-fluid py-5 about">
          <div className="row g-2 py-5">
            <div className="col-md-7 p-3">
              <div className="about-hero-img-container">
                <img src="https://friendkit.cssninja.io/assets/img/illustrations/characters/friends.svg" alt="" className="w-100" />  
              </div>
            </div>
            <div className="col-md-5 text-lg-light p-3 d-flex flex-column justify-content-center about-hero-text">
              <div className="title-fm rp-title-text">
                Developed this app friendly and happily
              </div>
              <div className='content-fm mt-3 '>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis adipisci labore aperiam quas delectus, qui suscipit atque mollitia et voluptas tenetur eligendi necessitatibus aspernatur sunt debitis eveniet quo, aliquam consectetur!
              </div>
            </div>
          </div>
        </div>

        <div className="respect"> 
            <div className='container py-5 '>
                <div className="row g-2 justify-content-xl-between ">
                <div className="col-lg-6 col-xl-5 p-3">
                    <div className='get-feel-img-container'>
                    <img src={confidence} alt="" className="w-100 get-feel-img" />
                    </div>
                </div>
                <div className="col-lg-6 col-xl-5 p-2 d-flex justify-content-center align-items-center">
                    <div className=' p-3'>
                    <div className='title-fm rp-title-text'>Can be used with confidence</div>
                    <div className="content-fm mt-3">
                        Find deals, make reservations and get customer support by chatting with your favorite businesses right from <b className='text-danger'>near#</b>.
                    </div>
                    </div>
                </div>
                </div>
            </div>

            <div className='container py-5'>
                <div className="row g-2 justify-content-xl-between">
                <div className="col-lg-6 col-xl-5 p-2 d-flex justify-content-center align-items-center">
                    <div className=' p-3'>
                    <div className='title-fm rp-title-text'>We respect your privacy</div>
                    <div className="content-fm mt-3">
                        Find deals, make reservations and get customer support by chatting with your favorite businesses right from <b className='text-danger'>near#</b>.
                    </div>
                    </div>
                </div>
                <div className="col-lg-6 col-xl-5 p-3">
                    <div className='get-feel-img-container'>
                    <img src={privacy} alt="" className="w-100 get-feel-img" />
                    </div>
                </div>
                </div>
            </div>
        </div>

        <div className='container py-5'>
              <div className="row g-2 justify-content-xl-between ">
                <div className="col-lg-6 col-xl-5 p-3">
                    <div className='get-feel-img-container'>
                    <img src={mission} alt="" className="w-100 get-feel-img" />
                    </div>
                </div>
                <div className="col-lg-6 col-xl-5 p-2 d-flex justify-content-center align-items-center">
                    <div className=' p-3'>
                    <div className='title-fm rp-title-text'>Our mission</div>
                    <div className="content-fm mt-3">
                        Find deals, make reservations and get customer support by chatting with your favorite businesses right from <b className='text-danger'>near#</b>.
                        Find deals, make reservations and get customer support by chatting with your favorite businesses right from <b className='text-danger'>near#</b>.
                    </div>
                    </div>
                </div>
              </div>

              <div className="row g-2 justify-content-xl-between">
                <div className="col-lg-6 col-xl-5 p-2 d-flex justify-content-center align-items-center">
                    <div className=' p-3'>
                    <div className='title-fm rp-title-text'>Our vision</div>
                    <div className="content-fm mt-3">
                        Find deals, make reservations and get customer support by chatting with your favorite businesses right from <b className='text-danger'>near#</b>.
                        Find deals, make reservations and get customer support by chatting with your favorite businesses right from <b className='text-danger'>near#</b>.
                    </div>
                    </div>
                </div>
                <div className="col-lg-6 col-xl-5 p-3">
                    <div className='get-feel-img-container'>
                    <img src={vision} alt="" className="w-100 get-feel-img" />
                    </div>
                </div>
              </div>
        </div>

    </div>
  )
}

export default About