import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className='footer p-4'>
        <div className='text-center content-fm-2 text' >
          Copyright © 2022 <b className="text-danger">NEARHASH</b>. All rights reserved.
        </div>
    </div>
  )
}

export default Footer