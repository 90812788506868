import React, { useEffect } from 'react'
import OtherNavbar from '../navbar/OtherNavbar';

const Application = () => {

  useEffect(()=> {
    console.log(window.location.pathname);
  }, [])

  return (
    <div>
        <OtherNavbar/>
        <div className="container py-5">
          <div className="row g-2 py-5">
            <div className="col-lg-6 p-3">
              <img src="https://scontent-ams2-1.xx.fbcdn.net/v/t39.8562-6/122094327_4414055992020754_4710698384098099221_n.png?_nc_cat=106&ccb=1-7&_nc_sid=6825c5&_nc_ohc=EMua3qp_U3cAX8vGqf2&_nc_ht=scontent-ams2-1.xx&oh=00_AfDVYg9Vx7EtQIj5-uRW1lv9wBoJGM9EjMMUDVK_Ja_tMA&oe=6389CD56" alt="" className="w-100" />
              <div className='d-flex justify-content-center'>
                <img className='m-2' width="180" src="https://staging.freshmoe.com/static/media/playstore_img.ba0de1d3.0a53de786e85ca108bfb.png" alt="" />
                <img className='m-2' width="180" src="https://staging.freshmoe.com/static/media/app_store.428f2d8e.d4f2ab82cde9124dc8a9.png" alt="" />
              </div>
            </div>
            <div className="col-lg-6 p-3 d-flex flex-column justify-content-center">
              <div className="title-fm rp-title-text">
                Get the App
              </div>
              <div className='content-fm mt-3'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis adipisci labore aperiam quas delectus, qui suscipit atque mollitia et voluptas tenetur eligendi necessitatibus aspernatur sunt debitis eveniet quo, aliquam consectetur!
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Application