import React from 'react'
import OtherNavbar from '../navbar/OtherNavbar'
import './Privacy.css'


const Privacy = () => {
  return (
    <div>
        <OtherNavbar/>

        <div className="container py-5">
            <div className="p-5"></div>
            <div className="py-5">
                <div className='p-3'>
                    <div className="text-center title-fm rp-title-text">
                        If you don't allow, nobody see your information.
                    </div>
                    <div className="content-fm mt-3">
                        You deserve a private and safe messaging experience, where you can express yourself the way you want to the people you want.
                    </div>
                </div>
                <div>
                    <div className="col-lg-10 col-xl-8 mx-auto p-3">
                        <img src="https://scontent-ams2-1.xx.fbcdn.net/v/t39.8562-6/122094327_4414055992020754_4710698384098099221_n.png?_nc_cat=106&ccb=1-7&_nc_sid=6825c5&_nc_ohc=EMua3qp_U3cAX8vGqf2&_nc_ht=scontent-ams2-1.xx&oh=00_AfDVYg9Vx7EtQIj5-uRW1lv9wBoJGM9EjMMUDVK_Ja_tMA&oe=6389CD56" alt="" className="w-100" />
                    </div>
                </div>
            </div>
        </div>

        <div className='cardsContainer container py-5'>
            <div className="row g-2 d-flex align-items-stretch">
                <div className="col-lg-4 p-3 text-center  ">
                    <div className="privacy-card card border-0 h-100">
                        <div className='title-fm-2 rp-title-text'>Preventing harmful messages</div>
                        <div className="content-fm mt-3">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Assumenda delectus id illum culpa soluta odit vel animi, ut ducimus, eligendi accusantium impedit rem officiis maxime. Recusandae eius animi optio fugit!
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 p-3 text-center">
                    <div className="privacy-card card border-0 h-100">
                        <div className='title-fm-2 rp-title-text'>Your privacy is your information</div>
                        <div className="content-fm mt-3">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Assumenda delectus id illum culpa soluta odit vel animi, ut ducimus, eligendi accusantium impedit rem officiis maxime. Recusandae eius animi optio fugit!
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 p-3 text-center">
                    <div className="privacy-card card border-0 h-100">
                        <div className='title-fm-2 rp-title-text'>You can controll all tools</div>
                        <div className="content-fm mt-3">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Assumenda delectus id illum culpa soluta odit vel animi, ut ducimus, eligendi accusantium impedit rem officiis maxime. Recusandae eius animi optio fugit!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Privacy