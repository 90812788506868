import React, { useState } from 'react'
import './Home.css'
import reality from '../../images/03.jpg'
import near from './../../images/home/near.jpg'

import sme from '../../images/home/sme.jpg'
import shop from '../../images/home/shop.jpg'
import sos from '../../images/home/sos.jpg'
import search from '../../images/home/new/search_motion_1.gif'
import love from '../../images/home/new/love.gif'

import shopBg from '../../images/home/shop-bg.jpg'
import loveBg from '../../images/home/love-bg.jpg'
import smeBg from '../../images/home/sme-bg.jpg'
import sosBg from '../../images/home/sos-bg.jpg'
import searchBg from '../../images/home/search-bg.jpg'

import Navbar from '../navbar/Navbar'

const Home = () => {
  const [image, setImage] = useState(searchBg)

  const backgroundHandle = (sign) => {
    if(sign === 'sme'){
      setImage(smeBg)
    }else if(sign === 'help') {
      setImage(sosBg)
    }else if(sign === 'search') {
      setImage(searchBg)
    }else if(sign === 'shop') {
      setImage(shopBg)
    }else if(sign === 'love') {
      setImage(loveBg)
    }
  }

  return (
    <div>

      <Navbar/>

      <div className='home-hero' id='home'>
        <div className='container'>
          <div className='hero-content title-fm text-light'>
            <span className='one'>Get Near,</span> <span className='two'>Feel Reality.</span>
          </div>
          <div className="mt-3 content-fm text-light col-xl-8">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eum minus debitis aut tenetur quidem. Architecto modi officia quo culpa vel veniam repellat ipsum exercitationem in harum eos suscipit, et eligendi!
          </div>
        </div>
      </div>


      <div className='container py-5'>
        <div className="row g-2 justify-content-xl-between">
          <div className="col-lg-6 col-xl-5 p-3">
            <div className='get-feel-img-container'>
              <img src={near} alt="" className="w-100 get-feel-img" />
            </div>
          </div>
          <div className="col-lg-6 col-xl-5 p-2 d-flex justify-content-center align-items-center">
            <div className='bussiness p-3'>
              <div className='title-fm rp-title-text'>Get Near</div>
              <div className="content-fm mt-3">
                Find deals, make reservations and get customer support by chatting with your favorite businesses right from <b className='text-danger'>near#</b>.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container py-5'>
        <div className="row g-2 justify-content-xl-between">
          <div className="col-lg-6 col-xl-5 p-2 d-flex justify-content-center align-items-center">
            <div className='bussiness p-3'>
              <div className='title-fm rp-title-text'>Feel Reality</div>
              <div className="content-fm mt-3">
                Find deals, make reservations and get customer support by chatting with your favorite businesses right from <b className='text-danger'>near#</b>.
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-5 p-3">
            <div className='get-feel-img-container'>
              <img src={reality} alt="" className="w-100 get-feel-img" />
            </div>
          </div>
        </div>
      </div>


      {/* <div className='custom-carousel'>
        <div className="over-hover">
          <IoIosMegaphone size={50} className="changeBtn" onClick={changeImageOne} />
          <FaHandsHelping size={50} className="changeBtn" onClick={changeImageTwo} />
          <GiHeartBeats size={50} className="changeBtn" onClick={changeImageThree} />
          <RiUserLocationFill size={50} className="changeBtn" onClick={changeImageFour} />
        </div>
          <div className="col-lg-6 mx-auto p-3">
            
          </div>
      </div> */}

      <div className='custom-carousel'>
        <div className="over-hover">
          <div className="row w-100 h-100 d-flex justify-content-center">
            <div onMouseOver={() => backgroundHandle('sme')} className="col-3 features-4 title-fm-2 d-flex justify-content-center align-items-center sme text-light">
              Sme
            </div>
            <div onMouseOver={() => backgroundHandle('help')} className="col-3 features-4 title-fm-2 d-flex justify-content-center align-items-center help text-light">
              Sos
            </div>
            <div onMouseOver={() => backgroundHandle('search')} className="col-3 features-4 title-fm-2 d-flex justify-content-center align-items-center love text-light">
              Search
            </div>
            <div onMouseOver={() => backgroundHandle('shop')} className="col-3 features-4 title-fm-2 d-flex justify-content-center align-items-center shop text-light">
              Shop
            </div>
          </div>
        </div>
        <div className="">
          <img src={image} alt="" className="custom-carousel-feature " />
        </div>
      </div>



      {/* SME */}
      <div className='container'>
        <div className="row g-2 justify-content-xl-between">
          <div className="col-lg-6 col-xl-5 p-2 d-flex justify-content-center align-items-center">
            <div className='bussiness p-3'>
              <div className='title-fm rp-title-text'>Connect With Your <b>SME</b></div>
              <div className="content-fm mt-3">
                Find deals, make reservations and get customer support by chatting with your favorite businesses right from <b className='text-danger'>near#</b>.
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-5 p-3">
            <img src={sme} alt="" className="w-100 mx-auto feature-img-animation" />
          </div>
        </div>
      </div>

      {/* HELP */}
      <div className='container'>
        <div className="row g-2 justify-content-xl-between">
          <div className="col-lg-6 col-xl-5 p-3">
            <img src={sos} alt="" className="w-100 feature-img-animation" />
          </div>
          <div className="col-lg-6 col-xl-5 p-2 d-flex justify-content-center align-items-center">
            <div className='bussiness p-3'>
              <div className='title-fm rp-title-text'><b>Help</b> The People With This App</div>
              <div className="content-fm mt-3">
                Find deals, make reservations and get customer support by chatting with your favorite businesses right from <b className='text-danger'>near#</b>.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SEARCH */}
      <div className='container'>
        <div className="row g-2 justify-content-xl-between">
          <div className="col-lg-6 col-xl-5 p-2 d-flex justify-content-center align-items-center">
            <div className='bussiness p-3'>
              <div className='title-fm rp-title-text'><b>Search</b> The People Around You</div>
              <div className="content-fm mt-3">
                Find deals, make reservations and get customer support by chatting with your favorite businesses right from <b className='text-danger'>near#</b>.
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-5 p-3">
            <img src={search} alt="" className="w-100 feature-img-animation" />
          </div>
        </div>
      </div>

      {/* BUY */}
      <div className='container'>
        <div className="row g-2 justify-content-xl-between">
          <div className="col-lg-6 col-xl-5 p-3">
            <img src={shop} alt="" className="w-100 feature-img-animation" />
          </div>
          <div className="col-lg-6 col-xl-5 p-2 d-flex justify-content-center align-items-center">
            <div className='bussiness p-3'>
              <div className='title-fm rp-title-text'><b>Shop</b> At Store Near You</div>
              <div className="content-fm mt-3">
                Find deals, make reservations and get customer support by chatting with your favorite businesses right from <b className='text-danger'>near#</b>.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* LOVE */}
      <div className='container'>
        <div className="row g-2 justify-content-xl-between">
          <div className="col-lg-6 col-xl-5 p-2 d-flex justify-content-center align-items-center">
            <div className='bussiness p-3'>
              <div className='title-fm rp-title-text'>Be Friendly With Your Friends</div>
              <div className="content-fm mt-3">
                Find deals, make reservations and get customer support by chatting with your favorite businesses right from <b className='text-danger'>near#</b>.
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xl-5 p-3">
            <img src={love} alt="" className="w-100 feature-img-animation" />
          </div>
        </div>
      </div>

    </div>
  )
}

export default Home