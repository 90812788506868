import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from 'react-icons/fa'
import { useLocation } from "react-router-dom";
import './Navbar.css'
import logo from '../../images/logo-1.png'

const OtherNavbar = () => {
    const [click, setClick] = useState(false)
    const [cls, setCls] = useState(false)

    const location = useLocation();
    console.log(location.pathname);

    const handleClick = () => setClick(!click)
    window.addEventListener("scroll", ()=>{
        if (window.pageYOffset>100) {
            setCls(true)
        } else {
            setCls(false)
        }
    });

    const gotop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <div className={"navbar-over-container-2 fixed-top " + (cls ? "whole-navbar-change-2" : "")}> 
            <div className="container">
                <div className='navbar'>
                    <div className='logo'>
                        <Link to='/' onClick={gotop}><img src={logo} alt='logo' /></Link>
                    </div>
                    <ul className={(click ? 'nav-menu active ' : 'nav-menu ')} style={{ top: cls ? ' 72px' : ' 87px' }}>
                        <li className='nav-item'><Link to='/'  className='text-dark' onClick={gotop}>Features</Link></li>
                        <li className='nav-item'><Link to='/app' className={(location.pathname === '/app' ? "text-dark active-link" : "text-dark")} onClick={gotop}>App</Link></li>
                        <li className='nav-item'><Link to='/about' className={(location.pathname === '/about' ? "text-dark active-link" : "text-dark")} onClick={gotop}>About</Link></li>
                        <li className='nav-item'><Link to='/privacy' className={(location.pathname === '/privacy' ? "text-dark active-link" : "text-dark")} onClick={gotop}>Privacy</Link></li>
                        <li className='nav-item'><Link to='/contact' className={(location.pathname === '/contact' ? "text-dark active-link" : "text-dark")} onClick={gotop}>Contact</Link></li>
                    </ul>
                    <div className='hamburger' onClick={handleClick}>
                        {click ? (<FaTimes size={30}  />) : (<FaBars size={30}  />)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OtherNavbar