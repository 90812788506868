import { Route, Routes } from 'react-router-dom';
import './App.css';
import './Global.css'
import About from './pages/about/About';
import Application from './pages/application/Application';
import Contact from './pages/contact/Contact';
import Footer from './pages/footer/Footer';
import Home from './pages/home/Home';
import Privacy from './pages/privacy/Privacy';

function App() {
  
  return (
    <div className="">
      
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/app" element={<Application/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/privacy" element={<Privacy/>} />
        <Route path="/contact" element={<Contact/>} />
      </Routes>

      <Footer/>

    </div>
  );
}

export default App;
