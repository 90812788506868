import React from 'react'
import OtherNavbar from '../navbar/OtherNavbar'
import './Contact.css'

const Contact = () => {
  return (
    <div>
      <OtherNavbar/>
      
        <div className="container-fluid py-5 contact">
          <div className="row g-2 py-5">
            <div className="col-md-7 p-3">
              <div className="contact-hero-img-container">
                <img src="https://friendkit.cssninja.io/assets/img/illustrations/characters/friends.svg" alt="" className="w-100" />  
              </div>
            </div>
            <div className="col-md-5 text-lg-light p-3 d-flex flex-column justify-content-center contact-hero-text">
              <div className="title-fm rp-title-text">
                Contact Us
              </div>
              <div className='content-fm mt-3 '>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis adipisci labore aperiam quas delectus, qui suscipit atque mollitia et voluptas tenetur eligendi necessitatibus aspernatur sunt debitis eveniet quo, aliquam consectetur!
              </div>
            </div>
          </div>
        </div>

        <div className="contact-form-container">
          <div className="container py-5">
            <div className="row g-2">
              <div className="col-lg-6 p-3 contact-col-divider contact_form_title">
                  <div className="title-fm rp-title-text">
                    Get in touch with us
                  </div>
                  <span className="contact_form_title_underline"></span>
                  <div className="mt-3 content-fm">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio, exercitationem quod. Temporibus, modi hic, minima optio exercitationem quasi nisi tempora tempore asperiores id ullam perferendis quae fugiat dicta vitae est!
                  </div>
              </div>
              <div className="col-lg-6 p-3">
                <div className="contact_form">
                    <form
                      action=""
                      className='content-fm'
                    >
                    <input
                        type="text"
                        name="name"
                        required
                        className="mb-3"
                        placeholder="Enter Your Name"
                        autocomplete="off"
                    />
                    <input
                        type="email"
                        name="email"
                        required
                        className="mb-3"
                        placeholder="Enter Your Email"
                        autocomplete="off"
                    />
                    <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="3"
                        className="mb-3"
                        placeholder="Enter Your Message"
                        autocomplete="off"
                    ></textarea>
                    <button type="submit">Send</button>
                  </form>
                </div>
              </div>
            </div>

            <div className="row g-2 mt-3">
          <div className="col-lg-6 p-3">
            <iframe style={{ width: '100%', height: '500px' }} src="https://maps.google.com/maps?q=Hlaing%20Township&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" id="gmap_canvas"  scrolling="no"  ></iframe>
          </div>
          <div className="col-lg-6 p-3 contact-col-divider contact_form_title">
              <div className="title-fm rp-title-text">
                Our Location
              </div>
              <span className="contact_form_title_underline"></span>
              <div className="mt-3 content-fm">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio, exercitationem quod. Temporibus, modi hic, minima optio exercitationem quasi nisi tempora tempore asperiores id ullam perferendis quae fugiat dicta vitae est!
              </div>
          </div>
        </div>

          </div>
        </div>

    </div>
  )
}

export default Contact